.link-list {
  @apply flex;

  &.info-list {
    li {
      @apply relative mr-[16px] text-[14px];

      &:after {
        @apply content-[''] absolute right-[-8px] top-1/2 -translate-y-1/2
        w-[0.5px] h-[12px] bg-textGray;
      }

      &:last-child:after {
        @apply hidden;
      }
    }
  }

  &.sns-list {
    li {
      @apply ml-[6px];
    }
  }
}
