a {
  @apply flex justify-center items-center gap-x-[8px] h-[48px] rounded-[60px] tracking-[-.03em] cursor-pointer;

  &.white-button {
    @apply bg-buttonWhite text-[12px] lg:text-[14px] text-textDarkgray font-medium px-[8px] md:px-[16px];
  }

  &.gray-button {
    @apply bg-buttonGray text-[12px] lg:text-[17px] text-textLightgray px-[8px] lg:px-[28px];
  }
}
